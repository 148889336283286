import { Home } from 'views/Home';
import { WhitelistWalletUser } from 'views/WhitelistWalletUser';

const privateRoute = {
  home: {
    path: '/home',
    component: Home,
    name: 'Dashboard',
  },
  whitelistWalletUser: {
    path: '/whitelist-wallet-user',
    component: WhitelistWalletUser,
    name: 'Whitelist'
  }
};

export default privateRoute;
