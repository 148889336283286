import { createSlice } from '@reduxjs/toolkit';

export const DARKMODE = 'darkmode';

export const coreuiSlice = createSlice({
  name: 'coreui',
  initialState: {
    alert: {
      message: '',
      variant: null,
      key: null,
      onUpdate: 0,
    },
    darkmode: null,
  },
  reducers: {
    enqueueSnackbar: (state, { payload: { message, variant, key } }) => {
      return { ...state, alert: { message, variant, key, onUpdate: Math.random() } };
    },
    updateDarkmode: (state, { payload: darkmode }) => {
      localStorage.setItem(DARKMODE, darkmode);
      return { ...state, darkmode };
    },
  },
});

export const { enqueueSnackbar, updateDarkmode } = coreuiSlice.actions;

export default coreuiSlice.reducer;
