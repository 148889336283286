import React, { } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import { FormControl } from '@mui/material';
import { walletUsersService } from 'services/walletUsers';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { store } from 'reducers';
import { enqueueSnackbar } from 'reducers/coreui';
import { VIPLevels } from './WhitelistWalletUser'

const UpdateWalletUser = ({findWalletUsersAfterWhitelist}) => {
    const { control, watch, setValue } = useForm({ mode: 'onChange' });
    const {
        walletAddr = '',
        email = '',
        levelId = 7,
    } = watch();

    const handleWhitelist = async() => {
        const resp = await walletUsersService.updateWalletUser({
            "walletAddr": walletAddr.toLowerCase(),
            "email": email.toLowerCase(),
            "levelId": levelId
        })
        store.dispatch(
            enqueueSnackbar({
                message: resp.message,
                variant: 'success',
            }));
        await findWalletUsersAfterWhitelist(walletAddr.toLowerCase())

        setValue("walletAddr",'')
        setValue("email",'')
        setValue("levelId", 7)
    }

    return (
        <Grid container spacing={3} mt={2} mb={5}>
            <Grid item xs={5}>
                <Controller
                    key='walletAddr'
                    name='walletAddr'
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <label>Wallet Address</label>
                            <TextField {...field} />
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={3}>
                <Controller
                    key='email'
                    name='email'
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <label>Email</label>
                            <TextField {...field} />
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    key='levelId'
                    name='levelId'
                    defaultValue={7}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                            <label>Level ID</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                height="100%"
                                value={value}
                                label="VIP"
                                onChange={onChange}
                            >
                                {VIPLevels.map((item) =>
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={1}>
                <Controller
                    key='ttttttt'
                    name='ttttttt'
                    defaultValue=''
                    control={control}
                    render={() => (
                        <FormControl>
                            <label>.</label>
                            <Button
                                disabled={walletAddr === '' || email === ''}
                                onClick={handleWhitelist}>Whitelist</Button>
                        </FormControl>
                    )}
                />
            </Grid>
        </Grid >
    )
}

export default UpdateWalletUser