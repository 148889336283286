import { client } from './axios';

const fetchOrdersDetail = (params) => client.get(`/admin/order_details`, { params });
const ordersAction = ({ id, ...body }) => {
  client.put(`/admin/order/${id}`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
export const ordersService = {
  fetchOrdersDetail,
  ordersAction,
};
