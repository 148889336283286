import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, Toolbar, Typography } from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';
import { currentNetwork } from 'utils/network';
import { privateRoute } from 'routes';

const Header = () => {
  const location = useLocation();

  const route = Object.values(privateRoute).find((item) => location.pathname.startsWith(item.path));

  return (
    <Toolbar color='inherit' className='App-Header'>
      <div>
        <Breadcrumbs className='mb-3'>
          <Link to={privateRoute.home.path}>
            <HomeOutlined />
          </Link>
          <Link to={location.pathname}>{route?.name}</Link>
        </Breadcrumbs>
        <Typography variant='h3' className='text-neutral-900'>
          {route?.name}
        </Typography>
      </div>

      <div className='flex-1' />
      <div className='self-start'>
        <Typography variant='h4'>{currentNetwork.name}</Typography>
      </div>
    </Toolbar>
  );
};

export default Header;
