import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { usePopup } from 'hooks';
import { useSnackbar } from 'notistack';
import { EnhancedTable, Spinner } from 'components';
import { TableToolbar } from 'mui-datatables';
import { Button, Dialog, Link, Grid, Paper, TextField, Typography } from '@mui/material';
import { FormControl, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ordersService } from 'services/orders';
import { currentNetwork } from 'utils/network';
import { DateTime } from 'luxon';

const STATUS_TABS = [
  { code: 2, name: 'PENDING', color: 'warning' },
  { code: 3, name: 'APPROVED', color: 'success' },
  { code: 4, name: 'REJECTED', color: 'error' },
  { code: 0, name: 'ALL' },
];

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = React.useState(STATUS_TABS[0].code);
  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup(false);
  const [chosenPopup, setChosenPopup] = React.useState({});

  const { data, refetch, isFetching } = useQuery(
    ['ordersService.fetchOrdersDetail'],
    () => ordersService.fetchOrdersDetail(),
    { placeholderData: { data_1: [], data_2: [] } },
  );

  const { control, watch } = useForm({ mode: 'onChange' });
  const { walletAddress = '', transactionId = '' } = watch();

  const dataList = React.useMemo(() => {
    const list = status === 0 ? data.data_1 : data.data_2;
    
    return (list ?? []).filter(
      (item) =>
        item.wallet_address?.includes(walletAddress) &&
        item.transaction_hash?.includes(transactionId) &&
        (status === 0 || item.status === status),
    );
  }, [data, status, walletAddress, transactionId]);

  const { mutate: submitOrder, isLoading: isLoadingSubmit } = useMutation(ordersService.ordersAction, {
    onSuccess: () => {
      onClosePopup();
      refetch();
      enqueueSnackbar('Update order successfully', { variant: 'success' });
    },
  });
  return (
    <Paper className='p-6'>
      <div className='flex justify-between items-center mb-6'>
        <ToggleButtonGroup exclusive color='info' value={status} onChange={(event, value) => setStatus(value)}>
          {STATUS_TABS.map((item, index) => (
            <ToggleButton className='px-10' key={index} value={item.code}>
              {item.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <LoadingButton variant='outlined' color='info' onClick={refetch}>
          Refresh
        </LoadingButton>
      </div>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={3}>
          <Controller
            name='walletAddress'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Wallet Address</label>
                <TextField {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            name='transactionId'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Transaction ID</label>
                <TextField {...field} />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Spinner spinning={isFetching}>
        <EnhancedTable
          data={dataList}
          columns={[
            { name: 'order_id', label: 'ID' },
            { name: 'email', label: 'Email' },
            {
              name: 'wallet_address',
              label: 'Wallet Address',
              options: { customBodyRender: (value) => <div className='max-w-md break-all'>{value}</div> },
            },
            {
              name: 'name',
              label: 'Product',
              options: {
                customBodyRender: (value) => {
                  return (Array.isArray(value) ? value : [value]).map((item, index) => (
                    <div className='whitespace-nowrap my-2' key={index}>
                      {item}
                    </div>
                  ));
                },
              },
            },
            {
              name: 'total_amount',
              label: 'Amount',
              options: {
                customBodyRender: (value, { rowIndex }) => {
                  return (Array.isArray(value) ? value : [value]).map((item, index) => (
                    <div className='whitespace-nowrap my-2' key={index}>
                      {`${Number(value)} ${dataList[rowIndex].currency}`} 
                    </div>
                  ));
                },
              },
            },
            {
              name: 'transaction_hash',
              label: 'Transaction ID',
              options: {
                customBodyRender: (value) => (
                  <div className='max-w-sm break-all'>
                    <span className='mr-2'>{value}</span>
                    <Link href={currentNetwork.viewTrans(value)} title='View transaction'>
                      <OpenInNew fontSize='small' className='cursor-pointer text-gray-600 hover:text-primary-main' />
                    </Link>
                  </div>
                ),
              },
            },
            {
              name: 'order_time',
              label: 'Time',
              options: {
                customBodyRender: (value) => DateTime.fromISO(value).toFormat('HH:mm dd/MM/yyyy'),
              },
            },
            {
              name: 'status',
              label: 'Status',
              options: {
                display: status !== 2 ? true : 'excluded',
                customBodyRender: (value) => STATUS_TABS.find((item) => item.code === value)?.name,
              },
            },
            {
              name: 'order_id',
              label: 'Actions',
              options: {
                download: false,
                display: status === 2 ? true : 'excluded',
                customBodyRender: (value) => (
                  <div className='flex space-x-2'>
                    <Button
                      color='success'
                      onClick={() => {
                        setChosenPopup({ id: value, status: 3, isApprove: true });
                        onOpenPopup();
                      }}
                      className='w-24'
                    >
                      Approve
                    </Button>
                    <Button
                      color='error'
                      onClick={() => {
                        setChosenPopup({ id: value, status: 4 });
                        onOpenPopup();
                      }}
                      className='w-24'
                    >
                      Reject
                    </Button>
                  </div>
                ),
              },
            },
          ]}
          options={{
            ...EnhancedTable.options,
            fixedHeader: true,
            tableBodyHeight: '500px',
            download: true,
            downloadOptions: {
              filename: 'ORDER_' + STATUS_TABS.find((item) => item.code === status)?.name + '.csv',
            },
          }}
          components={{
            TableToolbar: (props) => {
              return (
                <div style={{ position: 'absolute', top: -80, right: 0 }}>
                  <TableToolbar {...props} />
                </div>
              );
            },
          }}
        />
      </Spinner>

      <Dialog open={isOpenPopup}>
        <div className='p-10'>
          <Typography variant='h4' className='mb-3'>
            Confirmation
          </Typography>
          <Typography className='mb-6'>
            Are you sure you want to {chosenPopup.isApprove ? 'approve' : 'reject'} order?
          </Typography>
          <div className='flex justify-center space-x-3'>
            <Button color='inherit' className='w-24' onClick={onClosePopup}>
              Cancel
            </Button>
            <LoadingButton
              className='w-24'
              color={chosenPopup.isApprove ? 'success' : 'error'}
              loading={isLoadingSubmit}
              onClick={() => submitOrder(chosenPopup)}
            >
              {chosenPopup.isApprove ? 'Approve' : 'Reject'}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </Paper>
  );
};

export default Home;
