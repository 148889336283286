import { client } from './axios';

const fetchWalletUsers = (walletAddressOrEmail) =>
    client.get(`/admin/get-whitelist-wallet-user?wallet_address_or_email=${walletAddressOrEmail}`);
const updateWalletUser = (body) => client.post(`/admin/whitelist-wallet-user`, body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

const uploadWhitelistFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);

    return client.post('/admin/whitelist-users', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const walletUsersService = {
    fetchWalletUsers,
    updateWalletUser,
    uploadWhitelistFile,
};
