import { NETWORK_CHAIN_IDS } from 'env';

export const currentNetwork = [
  {
    name: 'Ethereum Mainnet',
    chainId: '0x1',
    viewTrans: (txHash) => `https://etherscan.io/tx/${txHash}`,
  },
  {
    name: 'Rinkeby Testnet',
    chainId: '0x4',
    viewTrans: (txHash) => `https://rinkeby.etherscan.io/tx/${txHash}`,
  },
  {
    name: 'Binance Smart Chain',
    chainId: '0x38',
    viewTrans: (txHash) => `https://bscscan.com/tx/${txHash}`,
  },
  {
    name: 'Binance Smart Chain',
    chainId: '0x61',
    viewTrans: (txHash) => `https://testnet.bscscan.com/tx/${txHash}`,
  },
].filter((item) => NETWORK_CHAIN_IDS.split('_').includes(item.chainId))[0] ?? { viewTrans: () => '' };
