import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { AppHeader, AppMenu } from 'containers';
import { Darkmode } from 'components';
import { AppBar, Avatar, Drawer, Typography } from '@mui/material';
import { browserHistory } from 'utils/history';
import { authRoute, privateRoute } from 'routes';

const PrivateLayout = () => {
  const { isLoggedIn, email } = useSelector(({ profile }) => profile);

  React.useEffect(() => {
    if (!isLoggedIn) {
      browserHistory.replace(authRoute.login.path);
    }
  }, [isLoggedIn]);

  return (
    <div className='App Private-Layout'>
      <Drawer variant='persistent' anchor='left' open className='App-Navbar'>
        <div className='flex flex-col items-center p-3'>
          <Avatar className='w-32 h-32 bg-yellow-600 text-5xl border-2 mb-2'>{email?.slice(0, 2).toUpperCase()}</Avatar>
          <Typography variant='h4' color='white'>
            {email}
          </Typography>
        </div>
        <AppMenu />
        <div className='flex-1' />
        <div className='flex justify-center'>
          <Darkmode init />
        </div>
      </Drawer>
      <main className='App-Main'>
        <AppBar position='sticky' elevation={0} color='default'>
          <AppHeader />
        </AppBar>
        <div className='App-Content'>
          <Switch>
            {Object.values(privateRoute).map(({ path, component }) => (
              <Route key={path} path={path} component={component} />
            ))}
            <Redirect from='/' to={privateRoute.home.path} />
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default PrivateLayout;
